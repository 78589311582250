import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as exploreListActions } from './ExploreListReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as trackActions } from 'modules/tracking/TrackingReducer'
import history from '../../history'
import { initialValues } from './config'
import { LOCATION_CHANGE } from 'connected-react-router'

/**
 * Request submit form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitFormRequest(services, { payload }) {
  const { activeStep } = payload
  try {
    yield put(trackActions.trackSubmitAction({}))
    switch (activeStep) {
      case 'list-selection':
        yield call(submitListIdsRequest, services, payload)
        break
      case 'filters':
        yield call(submitFiltersRequest, services, payload)
        break
      default:
        break
    }
  } catch (e) {
    yield put(exploreListActions.submitFormRequestFail())
    console.error(e)
  }
}

/**
 * Request list selection form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitListIdsRequest(services, payload) {
  try {
    yield put(
      exploreListActions.setFormState({
        ...payload,
        activeStep: 'filters'
      })
    )
    yield put(exploreListActions.submitFormRequestSuccess())
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(exploreListActions.submitFormRequestFail())
    console.error(e)
  }
}

/**
 * Request submit form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitFiltersRequest(services, payload) {
  const SmartsService = services('SmartsService')

  try {
    const {
      ids,
      combinationsCountry,
      combinationsProfession,
      combinationsSpecialty,
      combinations,
      allMembersSelected,
      filters,
      type
    } = payload

    // Build Combinations data
    let combinationsData = []
    if (!allMembersSelected) {
      if (
        combinationsCountry ||
        combinationsProfession ||
        combinationsSpecialty
      ) {
        combinationsData = [
          ...combinations,
          {
            country: combinationsCountry,
            profession: combinationsProfession,
            specialty: combinationsSpecialty
          }
        ]
      } else {
        combinationsData = [...combinations]
      }
    }

    // Create List
    const { id: uuid } = yield call([SmartsService, 'listsCreate'], {
      workflow: 'ExploreList',
      parameters: {
        ExploreList: {
          lists: ids
        }
      },
      combinations: combinationsData,
      filters
    })
    // Generate Charts
    const { charts, listSize, universe } = yield call(
      [SmartsService, 'listsChartsGeneration'],
      uuid
    )

    // Update form state accordingly
    yield put(
      exploreListActions.setFormState({
        ...payload,
        listId: uuid,
        activeStep: type === 'graph' ? 'charts' : 'project-metadata',
        combinationsCountry: '',
        combinationsProfession: '',
        combinationsSpecialty: '',
        combinations: combinationsData,
        chartsData: charts,
        listSize,
        universe
      })
    )

    yield put(exploreListActions.submitFormRequestSuccess())
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(exploreListActions.submitFormRequestFail())
    console.error(e)
  }
}

/**
 * Request project metadata form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* projectMetadataRequest(services, payload) {
  const SmartsService = services('SmartsService')
  const {
    listId: uuid,
    domain,
    clientProduct,
    brand,
    client,
    masterProject,
    project,
    notes,
    defaultCountry,
    reportName,
    type: action
  } = payload

  let projectMetadataPayload = {
    uuid,
    domain,
    clientProduct,
    brand: brand === '' ? [] : [brand],
    client,
    masterProject,
    project,
    notes,
    defaultCountry,
    tags: '',
    action,
    reportName
  }

  // Set specific payload values when domain is 'FW'
  if (domain === 'FW') {
    projectMetadataPayload = {
      ...projectMetadataPayload,
      client: null,
      project: null,
      masterProject: -1,
      clientProduct: null
    }
  }

  // Call endpoint
  yield call([SmartsService, 'setProjectMetadata'], projectMetadataPayload)
}

/**
 * Request list validation & submit
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitExploreListRequest(services, payload) {
  const SmartsService = services('SmartsService')
  const { listId: uuid, type } = payload

  // Validate List
  yield call([SmartsService, 'validateList'], { uuid })
  // Submit List
  const { message } = yield call([SmartsService, 'submitList'], { uuid, type })
  yield put(
    notificationActions.notificationEnqueue({
      message,
      duration: 0
    })
  )
}

/**
 * Request run analysis
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* completeExploreListRequest(services, { payload }) {
  try {
    yield put(trackActions.trackSubmitAction({}))
    // Submit Project Metadata
    yield call(projectMetadataRequest, services, payload)

    // Submit explore list
    yield call(submitExploreListRequest, services, payload)

    yield put(exploreListActions.submitFormRequestSuccess())
    yield call([history, history.replace], { pathname: '/' })
    yield put(
      exploreListActions.setFormState({
        ...initialValues
      })
    )
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(exploreListActions.submitFormRequestFail())
    console.error(e)
  }
}

function* resetForm(services, { payload }) {
  const { action } = payload

  if (action === 'REPLACE') {
    yield put(exploreListActions.setFormState({ ...initialValues }))
  }
}

export default function* watchExploreListRequest(services) {
  yield all([
    takeEvery('EXPLORE_LIST_SUBMIT_FORM_REQUEST', submitFormRequest, services),
    takeEvery(
      'EXPLORE_LIST_COMPLETE_REQUEST',
      completeExploreListRequest,
      services
    ),
    takeEvery(LOCATION_CHANGE, resetForm, services)
  ])
}
