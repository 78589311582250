import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import PropTypes from 'prop-types'
import { isEmpty } from 'utils'
import moment from 'moment'

import {
  getFetching,
  getFormState,
  getMessages,
  getFeedbackModal,
  // getShareModal,
  getEmailModal,
  getInviteModal,
  getActionHandler,
  // getCurrentPrompt,
  getUrlRequested,
  getIsTyping
} from './ChatSelector'
// import useAuth from 'modules/auth/useAuth'
import {
  getIsFetching,
  getRawHistory
} from 'modules/dougall/history/HistorySelector'
import { actions as chatActions } from './ChatReducer'
import { actions as historyActions } from 'modules/dougall/history/HistoryReducer'
import useMediaSize from 'hooks/useMediaSize'
import { getPathToRedirect } from 'modules/dougall/loading/LoadingSelector'
import useTracking from 'modules/dougall/tracking/useTracking'

import PromptBar from 'components/Dougall/PromptBar/PromptBar'
import MessageStream from 'components/Dougall/MessageStream/MessagesStream'
import Typography from 'components/ui/Typography'
import EmailModal from 'components/Dougall/Modal/EmailModal'
import queryString from 'query-string'
import FeedbackModal from 'components/Dougall/Modal/FeedbackModal'
import ShareModal from 'components/Dougall/Modal/ShareModal'

import styles from './Chat.module.sass'
import SessionService from 'services/SessionService'
import siteConfig from 'site.config.json'

const { routes } = siteConfig

function decodeHtml(html) {
  let txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

const Chat = ({ empty = true, footer = <div></div> }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [feedbackVar, setFeedbackVar] = useState('')
  const sawLanding = new SessionService().getFromCache('sawLanding', false)
  const isAnon = new SessionService().getFromCache('isAnon', false)
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const { trackSubmitAction, trackPageView, trackClickAction } = useTracking()

  //Role Limits
  // const { roleLimits } = useAuth()
  // const {
  //   ugc: { hasLikes, hasShare }
  // } = roleLimits

  const initialForm = {
    prompt: ''
  }
  const newTopicAnswer = {
    isAnswer: true,
    showWarning: false,
    answer: t('newTopicAnswer'),
    showSocial: false
  }
  const mediaSize = useMediaSize({
    mediaQueries: {
      small: '(max-width: 800px)'
    }
  })
  const isFetching = useSelector(getFetching())
  const isHistoryFetching = useSelector(getIsFetching())
  const rawHistory = useSelector(getRawHistory())
  const formState = useSelector(getFormState())
  const messages = useSelector(getMessages())
  const urlRequested = useSelector(getUrlRequested())
  const isTyping = useSelector(getIsTyping())

  const feedbackModal = useSelector(getFeedbackModal())
  // const shareModal = useSelector(getShareModal())
  const emailModal = useSelector(getEmailModal())
  const inviteModal = useSelector(getInviteModal())

  const actionHandler = useSelector(getActionHandler())
  // const currentPrompt = useSelector(getCurrentPrompt())

  const { answerId, promptId } = actionHandler
  const formStatePr = isEmpty(formState) ? initialForm : formState
  const messagesStream = [...messages]
  const [action, setAction] = useState('')
  const [activeModal, setActiveModal] = useState(null)

  const path = useSelector(getPathToRedirect()) || false
  const {
    source = undefined,
    prompt = undefined,
    promptNum = undefined,
    dspId = undefined,
    pId = undefined,
    articleId = undefined,
    type = undefined
  } = queryString.parse(path)

  // URL prompt params action
  useEffect(() => {
    if (dspId && prompt && promptNum && !isFetching) {
      dispatch(
        chatActions.promptRequest({
          hcpPrompt: decodeHtml(prompt),
          articleId,
          type: 'ubq3'
        })
      )
      dispatch(chatActions.setPromptNum(promptNum))
    }

    // DougallApps prompt handling, must have articleId & type
    else if (articleId && type) {
      // Get translated prompt to show
      let dougallAppPrompt
      switch (type) {
        case 'implications':
          dougallAppPrompt = t('dougallAppsPromptImplications')
          break
        case 'explain':
        default:
          dougallAppPrompt = t('dougallAppsPromptExplain')
          break
      }
      // Submit prompt
      dispatch(
        chatActions.promptRequest({
          hcpPrompt: dougallAppPrompt,
          articleId,
          type
        })
      )
    }

    // Prompt submitted in other site
    else if (prompt && source && !isFetching) {
      dispatch(
        chatActions.promptRequest({
          hcpPrompt: decodeHtml(prompt),
          articleId,
          type: source === 'DGPTLessons' ? 'lessons' : 'regular'
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prompt, promptNum])

  // Shared Prompts
  useEffect(() => {
    if (pId && source) {
      dispatch(historyActions.requestPromptHistory({ pId, source }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pId, source])

  // Load last thread
  useEffect(() => {
    if (
      !((dspId && prompt && promptNum) || (pId && source) || (prompt && source))
    ) {
      if (!isHistoryFetching && rawHistory.length > 0 && !messages.length) {
        const lastThreadId = rawHistory[0].applicationLinkedPromptId
        dispatch(
          historyActions.requestPromptHistory({ promptId: lastThreadId })
        )
        dispatch(historyActions.setSelectedThread({ id: lastThreadId }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHistoryFetching])

  // First Render
  useEffect(() => {
    // if landing page route is defined
    if (routes.landing) {
      // Redirect to landingPage !sawLanding, skip if dspId & prompt on url
      if (!sawLanding && !dspId && !prompt && !promptNum && !pId) {
        history.replace(routes.landing.path, {
          from: history.location.pathname
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // Validate user prompt access whenever the user lands on the page or click listener is executed
  // If click validation is not needed remove this useEffect, and check ChatSaga for more information
  useEffect(() => {
    window.addEventListener('click', handleDocumentClick)
    return () => {
      window.removeEventListener('click', handleDocumentClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlRequested])
  useEffect(() => {
    if (messages && !messages.length && !empty) {
      // Add initial prompt only if there are no messages in the store
      dispatch(
        chatActions.addMessages([
          {
            isAnswer: false,
            message: t('dougallGptInitialPrompt'),
            editable: false
          },
          {
            isAnswer: true,
            showWarning: false,
            answer: t('dougallGptInitialAnswer'),
            showSocial: false
          }
        ])
      )
    }
    // If the user is coming from an email do not validate prompt access on first render
    if (!(dspId && prompt && promptNum) && !source) {
      dispatch(chatActions.validatePromptAccess())
    }
    // track home view on chat page load.
    // trackPageView({
    //   title: `Site/View/Home`,
    //   clickType: 'site-view-home'
    // })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (feedbackModal) {
      trackPageView({
        title: `Site/View/Feedback-Overlay`,
        userData: {
          answerId,
          promptId
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackModal])

  useEffect(() => {
    const handleSubmission = async () => {
      if (isFetching || isTyping) {
        await new Promise(resolve => setTimeout(resolve, 100))
        setIsSubmitting(true)
      } else {
        setIsSubmitting(false)
      }
    }
    handleSubmission()
  }, [isFetching, isTyping, isSubmitting])

  const handleDocumentClick = event => {
    const chatHeader = document.querySelector('#chatPage_header')
    const chatContainer = document.querySelector('#chatPage_container')
    // Only listen to clicks inside the Chat Page (avoid notification redirection)
    if (
      !(chatHeader || chatContainer) ||
      (!chatHeader?.contains(event.target) &&
        !chatContainer?.contains(event.target))
    ) {
      return
    }
    dispatch(chatActions.validatePromptAccess())
    if (isAnon && urlRequested) {
      history.replace(routes.login.path)
    }
  }

  const handleSubmit = (values, { resetForm }) => {
    const { prompt } = values
    if (prompt?.trim() && !isFetching) {
      // track submit on prompt submit.
      // trackSubmitAction({
      //   title: `Site/Click/Prompt/form/submit`,
      //   clickType: 'prompt-bar',
      //   userData: {
      //     parentpromptId: parentPromptId || undefined
      //   }
      // })
      resetForm()
      dispatch(
        chatActions.promptRequest({ hcpPrompt: prompt, type: 'regular' })
      )
    }
  }

  const handleEdit = (prompt, { setFieldValue }) => {
    dispatch(
      chatActions.setFormState({
        prompt
      })
    )
    setFieldValue('prompt', prompt)
  }

  const handleNewTopic = () => {
    // Refresh History
    dispatch(historyActions.resetSelectedThread())
    dispatch(historyActions.requestHistory())

    // Reset Chat
    dispatch(chatActions.resetMessages())
    dispatch(chatActions.resetCurrentPrompt())
    dispatch(chatActions.resetPromptNum())
    dispatch(
      chatActions.addMessage({
        date: moment().format('DD/MM/YYYY'),
        isDivider: true
      })
    )
    dispatch(chatActions.addMessage(newTopicAnswer))

    // Reset Linked Prompt Id
    dispatch(chatActions.resetLinkedPromptId())
  }

  // Like or Dislike Handler
  const handleAction = ({ action, prompt, promptId, answerId, undo }) => {
    setAction(action)
    if (action === 'share') {
      trackClickAction({
        title: `Site/Click/Answer/share`,
        clickType: 'answer-share',
        userData: {
          answerId,
          promptId,
          source
        }
      })
      setActiveModal('share')
      dispatch(chatActions.openShareModal())
      dispatch(chatActions.setAction({ action, prompt, promptId, answerId }))
      trackPageView({
        title: `Site/View/Multishare-Overlay`,
        userData: {
          answerId,
          promptId,
          source
        }
      })
      return
    } else {
      trackClickAction({
        title: `Site/Click/Answer/${action}`,
        clickType: 'answer-action-bar',
        userData: {
          answerId,
          promptId
        }
      })
    }

    if (undo) {
      dispatch(
        chatActions.actionSubmit({
          action,
          promptId,
          answerId,
          undo
        })
      )
    } else {
      dispatch(chatActions.setAction({ action, promptId, answerId }))
      dispatch(chatActions.openFeedbackModal())
      trackPageView({
        title: 'Site/View/Feedback-Overlay',
        userData: {
          answerId,
          promptId
        }
      })
    }
  }

  const handleEmailSubmit = (values, { resetForm }) => {
    // trackClickAction({
    //   title: `Site/Click/Emailshare-Overlay/form/submit`,
    //   clickType: 'emailshare-overlay-form-submit',
    //   userData: {
    //     answerId,
    //     promptId,
    //     source
    //   }
    // })
    resetForm()
    dispatch(chatActions.shareEmail({ ...values, pId: promptId }))
    dispatch(chatActions.closeEmailModal())
    setActiveModal(null)
  }

  const handleInviteSubmit = (values, { resetForm }) => {
    // trackClickAction({
    //   title: `Site/Click/Invite-Colleagues-Overlay/form/submit`,
    //   clickType: 'inviteshare-overlay-form-submit',
    //   userData: {
    //     answerId,
    //     promptId,
    //     source
    //   }
    // })
    const articleUrl = 'dougallgpt'
    resetForm()
    dispatch(
      chatActions.shareEmail({
        ...values,
        articleUrl,
        brandType: 'inviteYourColleague'
      })
    )
    dispatch(chatActions.closeInviteModal())
    setActiveModal(null)
  }

  const handleTypingFinish = () => {
    dispatch(chatActions.setTyping(false))
  }

  /**
   *
   * @param {boolean} dimissedFeedback
   */
  const handleFeedbackSubmit = ({ dimiss, action }) => {
    if (dimiss) {
      dispatch(chatActions.closeFeedbackModal())
    } else {
      if (feedbackVar.trim()) {
        trackSubmitAction({
          title: 'Site/Click/Feedback-Overlay/form/submit',
          clickType: 'feedback-overlay-submit',
          userData: {
            answerId,
            promptId
          }
        })
      }
      dispatch(
        chatActions.actionSubmit({
          action,
          promptId,
          answerId,
          feedback: feedbackVar
        })
      )
      dispatch(chatActions.closeFeedbackModal())
      setFeedbackVar('')
    }
  }

  const handleEmailClick = () => {
    trackClickAction({
      title: `Site/Click/Multishare-Overlay/share/email`,
      clickType: 'multishare-overlay-share-email',
      userData: {
        answerId,
        promptId,
        source
      }
    })

    dispatch(chatActions.closeShareModal())
    setActiveModal('email')

    trackPageView({
      title: `Site/View/Emailshare-Overlay`,
      userData: {
        answerId,
        promptId,
        source
      }
    })
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={formStatePr}
        onSubmit={handleSubmit}
      >
        {formProps => (
          <Form autoComplete="off" className={styles.container}>
            <div className={styles.flex}>
              <div className={styles.chat}>
                <MessageStream
                  messages={messagesStream}
                  isLoading={isFetching}
                  isTyping={isTyping}
                  onEditClick={prompt => handleEdit(prompt, formProps)}
                  onAction={handleAction}
                  showShare={false}
                  showLikes={false}
                  onTypingFinish={handleTypingFinish}
                />
                {mediaSize === 'small' && (
                  <div className={styles.footerBackground}>{footer}</div>
                )}
              </div>
              <div className={styles.bottomContainer}>
                <div className={styles.scrollFade} />
                <PromptBar
                  name="prompt"
                  placeholder={t('chatWithGptPlaceHolder')}
                  onNewTopicClick={handleNewTopic}
                  isSubmitting={isSubmitting}
                />
                <Typography
                  className={styles.warning}
                  align="center"
                  type="greeting"
                >
                  {t('warningPromptBar')}
                </Typography>
              </div>
            </div>
            {/* Feedback Modal for Like or Dislike */}
            <FeedbackModal
              open={feedbackModal}
              onCancel={() => handleFeedbackSubmit({ dimiss: true, action })}
              onConfirm={() => handleFeedbackSubmit({ dimiss: false, action })}
              onTextAreaChange={e => {
                setFeedbackVar(e.target.value.trim())
              }}
            />
            {/* Share Modal */}
            <ShareModal
              open={activeModal === 'share'}
              onCancel={() => {
                trackClickAction({
                  title: `Site/Click/Multishare-Overlay/form/close`,
                  clickType: 'multishare-overlay-form-close',
                  userData: {
                    answerId,
                    promptId,
                    source
                  }
                })
                dispatch(chatActions.closeShareModal())
                setActiveModal(null)
              }}
              onConfirm={() => dispatch(chatActions.openInviteModal())}
              onEmailClick={() => handleEmailClick()}
            />
            {/* Email Share Modal */}
            <EmailModal
              open={activeModal === 'email'}
              title={t('sharePromptTitle')}
              defaultMessage={t('shareDefaultMessage')}
              onCancel={() => {
                trackClickAction({
                  title: `Site/Click/Emailshare-Overlay/form/close`,
                  clickType: 'emailshare-overlay-form-close',
                  userData: {
                    answerId,
                    promptId,
                    source
                  }
                })
                setActiveModal('share')
              }}
              onConfirm={handleEmailSubmit}
            />
            {/* Invite Modal */}
            <EmailModal
              open={activeModal === 'invite'}
              title={t('inviteFormTitle')}
              defaultMessage={t('shareInviteDefaultMessage')}
              onCancel={() => {
                trackClickAction({
                  title: `Site/Click/Invite-Colleagues-Overlay/form/close`,
                  clickType: 'inviteshare-overlay-form-close',
                  userData: {
                    answerId,
                    promptId,
                    source
                  }
                })
                setActiveModal('share')
              }}
              onConfirm={handleInviteSubmit}
              invite
            />
          </Form>
        )}
      </Formik>
      {/* Email Share Modal */}
      <EmailModal
        open={emailModal}
        title={t('sharePromptTitle')}
        defaultMessage={t('shareDefaultMessage')}
        onCancel={() => {
          trackClickAction({
            title: `Site/Click/Emailshare-Overlay/form/close`,
            clickType: 'emailshare-overlay-form-close',
            userData: {
              answerId,
              promptId,
              source
            }
          })
          dispatch(chatActions.closeEmailModal())
        }}
        onConfirm={handleEmailSubmit}
      />
      {/* Invite Modal */}
      <EmailModal
        open={inviteModal}
        title={t('inviteFormTitle')}
        defaultMessage={t('shareInviteDefaultMessage')}
        onCancel={() => {
          trackClickAction({
            title: `Site/Click/Invite-Colleagues-Overlay/form/close`,
            clickType: 'inviteshare-overlay-form-close',
            userData: {
              answerId,
              promptId,
              source
            }
          })
          dispatch(chatActions.closeInviteModal())
        }}
        onConfirm={handleInviteSubmit}
        invite
      />
    </>
  )
}

Chat.propTypes = {
  empty: PropTypes.bool,
  footer: PropTypes.node
}

export default Chat
