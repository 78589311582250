import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as exportListActions } from './ExportListReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as trackActions } from 'modules/tracking/TrackingReducer'
import SmartsMLError from 'services/SmartsMLError'
import { initialValues } from './config'
import { isCombinationValid } from 'utils'
import { LOCATION_CHANGE } from 'connected-react-router'
// import { smartsEndpoints } from 'constants'

/**
 * Request submit form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitFormRequest(services, { payload }) {
  try {
    yield put(trackActions.trackSubmitAction({}))
    yield call(listExportRequest, services, payload.values)
  } catch (e) {
    yield put(exportListActions.submitFormRequestFail())
    console.error(e)
  }
}

/**
 * Request list selection form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* listExportRequest(services, payload) {
  const SmartsService = services('SmartsService')
  const {
    activeStep,
    exportToHopper,
    exportToFile,
    // targetSpecialtyBoolean,
    brand,
    maconomyNumber,
    waveNumber,
    // maxSize,
    lengthOfInterview,
    ids,
    selectedLists,
    combinations,
    // targetSpeciality,
    combinationsCountry,
    combinationsProfession,
    combinationsSpecialty,
    filters,
    reportName,
    uuid
  } = payload

  // Update formState with payload
  yield put(
    exportListActions.setFormState({
      ...payload
    })
  )

  const exportToHopperBoolean = exportToHopper === 'Yes'
  const exportToFileBoolean = exportToFile === 'Yes'

  // Build Combinations data
  let combinationsData = []
  if (
    isCombinationValid(
      combinationsCountry,
      combinationsProfession,
      combinationsSpecialty
    )
  ) {
    combinationsData = [
      ...combinations,
      {
        id: combinations.length + 1,
        country: combinationsCountry,
        profession: combinationsProfession,
        specialty: combinationsSpecialty
      }
    ]
  } else {
    combinationsData = [...combinations]
  }
  //   combinationsData =
  //     combinations.length === 0
  //       ? [
  //           ...targetSpeciality,
  //           {
  //             id: targetSpeciality.length + 1,
  //             country: combinationsCountry,
  //             profession: combinationsProfession,
  //             specialty: combinationsSpecialty
  //           }
  //         ]
  //       : [
  //           ...combinations,
  //           {
  //             id: combinations.length + 1,
  //             country: combinationsCountry,
  //             profession: combinationsProfession,
  //             specialty: combinationsSpecialty
  //           }
  //         ]
  // } else {
  //   combinationsData =
  //     combinations.length === 0 ? [...targetSpeciality] : [...combinations]
  // }

  try {
    let requestBody = {
      filters,
      combinations: combinationsData,
      parameters: {
        reportName: ids[0] === reportName ? reportName : undefined
      }
    }
    // ExportLists workflow
    if (activeStep === 'export-list') {
      requestBody = { ...requestBody, workflow: 'ExportLists' }

      if (exportToHopperBoolean) {
        requestBody = {
          ...requestBody,
          parameters: {
            ...requestBody.parameters,
            ExportLists: {
              brand: parseInt(brand),
              maconomyNumber: parseInt(maconomyNumber),
              waveNumber: parseInt(waveNumber),
              // maxSize: parseInt(maxSize),
              loi: parseInt(lengthOfInterview),
              lists: ids[0] === reportName ? selectedLists : ids,
              exportToHopper: exportToHopperBoolean,
              exportToFile: exportToFileBoolean
              // targetSpecialty: targetSpecialtyBoolean
            }
          }
        }
      } else if (exportToFileBoolean) {
        requestBody = {
          ...requestBody,
          parameters: {
            ...requestBody.parameters,
            ExportLists: {
              brand: parseInt(brand),
              lists: ids[0] === reportName ? selectedLists : ids,
              exportToHopper: exportToHopperBoolean,
              exportToFile: exportToFileBoolean
              // targetSpecialty: targetSpecialtyBoolean
            }
          }
        }
      } else {
        // Either Export to hopper or export to files should be selected
        throw new Error(
          'Either Export To Files or Export To Hopper should be selected'
        )
      }
    } else {
      // If there's no list id show error message
      if (!uuid) {
        throw new Error('Please upload a file to continue')
      }
      // CreateList workflow
      requestBody = {
        ...requestBody,
        workflow: 'CreateList',
        filters: null,
        combinations: null,
        parameters: {
          CreateList: {
            id: uuid,
            exportToHopper: true,
            exportToFile: false,
            // targetSpecialty: targetSpecialtyBoolean,
            brand: parseInt(brand),
            maconomyNumber: parseInt(maconomyNumber),
            waveNumber: parseInt(waveNumber),
            // maxSize: parseInt(maxSize),
            loi: parseInt(lengthOfInterview)
          }
        }
      }
    }

    // Create list
    const { id: listId } = yield call(
      [SmartsService, 'listCreateUpdate'],
      requestBody
    )

    // Validate List
    yield call([SmartsService, 'validateList'], { uuid: listId })
    // Submit List
    const { message } = yield call([SmartsService, 'submitList'], {
      uuid: listId,
      type: 'export'
    })
    // Display success message
    yield put(
      notificationActions.notificationEnqueue({
        message,
        duration: 0
      })
    )
    // Call success action & reset form
    yield put(exportListActions.submitFormRequestSuccess())
    yield put(
      exportListActions.setFormState({
        ...initialValues
      })
    )
  } catch (e) {
    let errorMessage = e.message
    yield put(
      notificationActions.notificationEnqueue({
        message: errorMessage,
        duration: 0
      })
    )
    yield put(exportListActions.submitFormRequestFail())
    console.error(e)
  }
}

/**
 * Request file upload
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* fileUploadRequest(services, { payload }) {
  const SmartsService = services('SmartsService')
  const SmartsParser = services('SmartsParser')
  const { file } = payload

  try {
    // Get Signed URL endpoint call
    const { url, fields } = yield call([SmartsService, 'getSignedUrl'], {
      workflow: 'CreateList',
      fileName: file[0].name,
      contentType: file[0].type
    })

    // Call signedURl with file
    let formData = new FormData()
    formData.append('key', fields.key)
    formData.append('uuid', fields.uuid)
    formData.append('fileName', fields.fileName)
    formData.append('contentType', fields.contentType)
    formData.append('bucket', fields.bucket)
    formData.append('X-Amz-Algorithm', fields['X-Amz-Algorithm'])
    formData.append('X-Amz-Credential', fields['X-Amz-Credential'])
    formData.append('X-Amz-Date', fields['X-Amz-Date'])
    formData.append('X-Amz-Security-Token', fields['X-Amz-Security-Token'])
    formData.append('Policy', fields.Policy)
    formData.append('X-Amz-Signature', fields['X-Amz-Signature'])
    formData.append('file', file[0])

    yield call(
      [SmartsService, 'signedUrl'],
      url,
      // smartsEndpoints.listsSignedUrl, // For dev only. Do not use this on production
      formData
    )

    // Call upload endpoint
    const { uuid } = yield call([SmartsService, 'uploadFile'], {
      workflow: 'CreateList',
      uuid: fields.uuid,
      fileKey: fields.key,
      fileName: fields.fileName
    })

    // Use the uuid as a listId to later send it
    yield put(
      exportListActions.setFormState({
        ...payload,
        uuid,
        fileUploaded: true
      })
    )

    yield put(exportListActions.uploadFileRequestSuccess())
    yield put(
      notificationActions.notificationEnqueue({
        message: 'File uploaded successfully',
        duration: 5000
      })
    )
  } catch (e) {
    let errorMessage = e.message
    if (e instanceof SmartsMLError) {
      errorMessage = yield call(
        [SmartsParser, 'uploadFileErrorParser'],
        e.getResponseData()
      )
    }
    yield put(
      notificationActions.notificationEnqueue({
        message: errorMessage,
        duration: 0
      })
    )
    yield put(exportListActions.uploadFileRequestFail())
    console.error(e)
  }
}

/**
 * Request list validation & submit
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitExportListRequest(services, payload) {
  const SmartsService = services('SmartsService')
  const { listId: uuid } = payload

  // Validate List
  yield call([SmartsService, 'validateList'], { uuid })
  // Submit List
  const { message } = yield call([SmartsService, 'submitList'], {
    uuid,
    type: 'export'
  })
  yield put(
    notificationActions.notificationEnqueue({
      message,
      duration: 0
    })
  )
}

/**
 * Request run analysis
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* completeExportListRequest(services, { payload }) {
  try {
    yield put(trackActions.trackSubmitAction({}))
    // Submit export list
    yield call(submitExportListRequest, services, payload)

    yield put(
      exportListActions.setFormState({
        ...initialValues
      })
    )
    yield put(exportListActions.submitFormRequestSuccess())
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(exportListActions.submitFormRequestFail())
    console.error(e)
  }
}

function* getOptions(services, { payload }) {
  const SmartsService = services('SmartsService')

  try {
    // Call endpoint
    const { data } = yield call([SmartsService, 'getBrands'])
    const brands = data.reduce(
      (acc, cur) => ({ ...acc, [cur.key]: cur.caption }),
      {}
    )

    yield put(
      exportListActions.getBrands({
        brandOptions: brands
      })
    )
    yield put(exportListActions.getBrandsRequestSuccess())
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(exportListActions.getBrandsRequestFail())
    console.error(e)
  }
}

function* resetForm(services, { payload }) {
  const { action } = payload

  if (action === 'REPLACE') {
    yield put(exportListActions.setFormState({ ...initialValues }))
  }
}

export default function* watchExploreListRequest(services) {
  yield all([
    takeEvery('EXPORT_LIST_GET_BRANDS_REQUEST', getOptions, services),
    takeEvery('EXPORT_LIST_SUBMIT_FORM_REQUEST', submitFormRequest, services),
    takeEvery(
      'EXPORT_LIST_COMPLETE_REQUEST',
      completeExportListRequest,
      services
    ),
    takeEvery('EXPORT_LIST_UPLOAD_FILE_REQUEST', fileUploadRequest, services),
    takeEvery(LOCATION_CHANGE, resetForm, services)
  ])
}
