import axios from 'axios'
import { SSE } from 'sse'
// import Cookies from 'js-cookie'
// import { getValue } from 'utils'
// import { sessionStorage } from 'constants'

const DougallML = (DougallMLInterceptors, SessionService, CookieService) => ({
  createClient() {
    const instance = axios.create({})
    instance.interceptors.request.use(
      null,
      DougallMLInterceptors.catchNetworkErrors
    )
    instance.interceptors.response.use(
      null,
      DougallMLInterceptors.detectMLfailuresOnFail
    )
    return instance
  },

  async fetch(endpoint, method = 'GET', data = {}, extra = {}) {
    const { headers = {}, ...restExtra } = extra
    // const { access } = SessionService.getFromCache('state', '')
    const gpt = SessionService.getFromCache('gpt', {})
    const interactionId = decodeURIComponent(
      CookieService.getFromCache('interactionId', '')
    )
    const conf = {
      url: endpoint,
      method,
      data: { ...data, interactionId },
      ...restExtra,
      headers: {
        Authorization: `Bearer ${gpt.access}`,
        'Content-Type': 'application/json',
        ...headers
      }
    }

    const client = this.createClient()
    return client.request(conf)
  },

  async sseFetch(endpoint, method = 'GET', data = {}, extra = {}) {
    const { headers = {}, ...restExtra } = extra
    // const { access } = SessionService.getFromCache('state', '')
    const gpt = SessionService.getFromCache('gpt', {})
    const interactionId = decodeURIComponent(
      CookieService.getFromCache('interactionId', '')
    )

    const options = {
      method,
      payload: JSON.stringify({ ...data, interactionId }),
      ...restExtra,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${gpt.access}`,
        ...headers
      }
    }

    const response = new SSE(endpoint, { ...options })
    return response
  }
})

export default DougallML
