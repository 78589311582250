import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import moment from 'moment'

import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'

import PromptList from 'components/Dougall/PromptList/PromptList'
import Typography from 'components/ui/Typography/Typography'
import TextInput from 'components/TextInput'
import Modal from 'components/Dougall/Modal'
import Spinner from 'components/Dougall/ui/Spinner/Spinner'

import { actions as historyActions } from './HistoryReducer'
import { actions as chatActions } from 'modules/dougall/chat/ChatReducer'
import { actions as chatPageActions } from 'modules/dougall/chatPage/ChatPageReducer'
import useTracking from 'modules/dougall/tracking/useTracking'
import {
  getDeletingId,
  getHistory,
  getIsDeleting,
  getIsFetching,
  isClearAllOpen,
  getSelectedThread
} from './HistorySelector'
import styles from './History.module.sass'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { CheckIcon, ClockRewind, DeleteIcon, EditIcon } from 'static/icons'

const cx = classNames.bind(styles)

/**
 * Custom Icon Button
 * forwardProps: active
 */
const CustomIconButton = ({ icon, ...props }) => {
  const Icon = styled(IconButton)(() => ({
    width: '30px',
    height: '30px',
    margin: 'auto 3px'
  }))
  return <Icon {...props} />
}
CustomIconButton.propTypes = {
  /**
   * Icon component to be rendered
   */
  icon: PropTypes.func
}

/**
 * Custom Icon
 * forwardProps: nightMode
 */
const CustomIcon = ({ icon, ...props }) => {
  const Icon = styled(icon, {
    shouldForwardProp: prop => prop !== 'nightMode'
  })(({ nightMode }) => ({
    fontSize: '20px',
    fill: nightMode ? '#b3b8db' : '#5a5a5a',
    'g>g': {
      fill: nightMode ? '#b3b8db' : '#5a5a5a'
    },
    transition: '.3s',
    '&:hover': {
      fill: nightMode ? '#fff' : '#0164b0',
      'g>g': {
        fill: nightMode ? '#fff' : '#0164b0'
      }
    }
  }))
  return <Icon {...props} />
}
CustomIcon.propTypes = {
  /**
   * Icon component to be rendered
   */
  icon: PropTypes.func
}

const HistoryItem = ({ item, nightMode, selected = false }) => {
  const dispatch = useDispatch()
  const isDeleting = useSelector(getIsDeleting())
  const deletingId = useSelector(getDeletingId())
  const [isEditing, setIsEditing] = useState(false)
  const { id, caption, createdDate } = item
  // tracking on view of answer
  const { trackClickAction } = useTracking()
  const { t } = useTranslation()

  const newTopicAnswer = {
    isAnswer: true,
    showWarning: false,
    answer: t('newTopicAnswer'),
    showSocial: false
  }

  const resetChat = () => {
    dispatch(chatActions.resetMessages())
    dispatch(chatActions.resetCurrentPrompt())
    dispatch(chatActions.resetPromptNum())
    dispatch(
      chatActions.addMessage({
        date: moment().format('DD/MM/YYYY'),
        isDivider: true
      })
    )
    dispatch(chatActions.addMessage(newTopicAnswer))
  }

  const handleClick = () => {
    dispatch(historyActions.requestPromptHistory({ promptId: id }))
    dispatch(historyActions.setSelectedThread({ id }))
    dispatch(chatPageActions.displayContainer({ container: 'chat' }))
    trackClickAction({
      title: `Site/Click/History/prompt/submit`,
      clickType: 'history-submit',
      userData: {
        promptId: id
      }
    })
  }
  const handleEdit = () => {
    setIsEditing(true)
  }

  const onEditConfirm = ({ historyPrompt }, { setFieldValue }) => {
    if (historyPrompt.trim()) {
      dispatch(historyActions.requestTitleChange({ id, title: historyPrompt }))
      setFieldValue('historyPrompt', historyPrompt.trim())
      setIsEditing(false)
    }
  }
  const onEditCancel = setFieldValue => {
    setFieldValue('historyPrompt', caption)
    setIsEditing(false)
  }

  const handleRemove = (id, date) => {
    dispatch(historyActions.deleteHistory({ id, date, t }))
    // tracking pencil del button click
    trackClickAction({
      title: `Site/Click/History/prompt/delete`,
      clickType: 'history-delete',
      userData: {
        promptId: id
      }
    })

    resetChat()
  }
  return (
    <div className={cx(selected && styles.highlighted, { nightMode })}>
      <Formik
        initialValues={{ historyPrompt: caption }}
        onSubmit={onEditConfirm}
      >
        {({ values, setFieldValue, submitForm }) => (
          <Form autoComplete="off">
            {isEditing ? (
              <div className={styles.historyItem}>
                <TextInput
                  name="historyPrompt"
                  className={cx(styles.input, { nightMode })}
                  traditional={false}
                  autoFocus
                />
                <CustomIconButton
                  onClick={() => submitForm()}
                  onMouseDown={event => {
                    event.preventDefault()
                  }}
                >
                  <CustomIcon icon={CheckIcon} nightMode={nightMode} />
                </CustomIconButton>
                <CustomIconButton
                  onClick={() => onEditCancel(setFieldValue)}
                  onMouseDown={event => {
                    event.preventDefault()
                  }}
                >
                  <CustomIcon icon={CloseIcon} nightMode={nightMode} />
                </CustomIconButton>
              </div>
            ) : (
              <div className={styles.historyItem}>
                <Typography
                  className={cx(styles.label, { nightMode })}
                  type="greeting"
                  onClick={handleClick}
                >
                  {values.historyPrompt}
                </Typography>
                <CustomIconButton
                  onClick={handleEdit}
                  onMouseDown={event => {
                    event.preventDefault()
                  }}
                >
                  <CustomIcon
                    icon={EditIcon}
                    nightMode={nightMode}
                    sx={{ fontSize: '16px' }}
                  />
                </CustomIconButton>
                {isDeleting && deletingId === id ? (
                  <div className={styles.spinnerContainer}>
                    <Spinner
                      fillColor="transparent"
                      strokeColor="#0164b0"
                      size={20}
                    />
                  </div>
                ) : (
                  <CustomIconButton
                    onClick={() => handleRemove(id, createdDate)}
                    onMouseDown={event => {
                      event.preventDefault()
                    }}
                  >
                    <CustomIcon
                      icon={DeleteIcon}
                      nightMode={nightMode}
                      sx={{ fontSize: '20px' }}
                    />
                  </CustomIconButton>
                )}
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

HistoryItem.propTypes = {
  item: PropTypes.object,
  nightMode: PropTypes.bool,
  selected: PropTypes.bool
}

const History = ({ nightMode = false, openCallback, isOpen }) => {
  const dispatch = useDispatch()
  const clearAllOpen = useSelector(isClearAllOpen())
  const history = useSelector(getHistory())
  const isFetching = useSelector(getIsFetching())
  const isDeleting = useSelector(getIsDeleting())
  const selectedThread = useSelector(getSelectedThread())
  // tracking on view of answer
  const { trackClickAction } = useTracking()
  const { t } = useTranslation()
  const handleCloseModal = () => {
    dispatch(historyActions.setClearAllOpen({ open: false }))
  }

  const handleOpenModal = () => {
    dispatch(historyActions.setClearAllOpen({ open: true }))
    // tracking clear-all click
    trackClickAction({
      title: `Site/Click/History/clear-all`,
      clickType: 'history-clear-all'
    })
  }

  const handleDeleteAll = () => {
    dispatch(historyActions.deleteHistory({ t }))
    // tracking clear-all click
    trackClickAction({
      title: `Site/Click/History/clear-all/submit`,
      clickType: 'history-clear-all-submit'
    })
  }
  return (
    <div>
      <PromptList
        id="history"
        title={t('history')}
        icon={nightMode ? ClockRewind : null}
        items={history}
        customChildrenRender={item => (
          <HistoryItem
            nightMode={nightMode}
            item={item}
            selected={selectedThread === item.id}
          />
        )}
        isFetching={isFetching}
        listClassName={styles.listContainer}
        collapsibleContent={nightMode}
        nightMode={nightMode}
        onExpandClick={e =>
          trackClickAction({
            title: `Site/Click/History/${e}/expand`,
            clickType: 'history-item-expand'
          })
        }
        button={history.length > 0}
        buttonLabel={t('historyClearAll')}
        onButtonClick={handleOpenModal}
        manualOpenCallback={openCallback}
        isOpen={isOpen}
        dividers={false}
        autoIndent={false}
      />
      <Modal
        open={clearAllOpen}
        message="Hello"
        actionCaption={t('historyClearAllSubmitButton')}
        onCancel={handleCloseModal}
        onConfirm={handleDeleteAll}
        submitting={isDeleting}
      >
        <div className={styles.clealAllContainer}>
          <Typography type="display2">
            {t('historyClearAllModalTitle')}
          </Typography>
          <Typography className={styles.clearAllMessage} type="greeting">
            {t('historyClearAllModalSubtitle')}
          </Typography>
        </div>
      </Modal>
    </div>
  )
}

History.propTypes = {
  /**
   * Styles for dark or light background
   */
  nightMode: PropTypes.bool,
  /**
   * Callback when manually controlled opening
   */
  openCallback: PropTypes.func,
  /**
   * Variable for manual opening
   */
  isOpen: PropTypes.bool
}

export default History
