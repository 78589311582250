import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as filterActions } from './FilterReducer'

/**
 * Request filters
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* filtersRequest(services, { payload }) {
  try {
    const SmartsService = services('SmartsService')
    // Call endpoint
    const filters = yield call([SmartsService, 'fetchFilters'])
    // Update
    yield put(filterActions.filterRequestSuccess(filters))
  } catch (e) {
    yield put(filterActions.filterRequestFail())
    console.error(e)
  }
}

export default function* watchListIdRequest(services) {
  yield all([takeEvery('FILTERS_REQUEST', filtersRequest, services)])
}
