import { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

import IconButton from '@mui/material/IconButton'

import Typography from 'components/ui/Typography/Typography'
import Divider from 'components/ui/Divider'
import Spinner from 'components/ui/Spinner/Spinner'

import styles from './PromptList.module.sass'
import classNames from 'classnames/bind'
import { CaratDown, CaratUp } from 'static/icons'

const cx = classNames.bind(styles)

const PromptListButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'nightMode'
})(props => ({
  display: 'block',
  margin: '0 11px 0 auto',
  color: props.nightMode ? '#58b7ff' : '#363f72',
  textTransform: 'capitalize',
  fontSize: '12px',
  fontWeight: '600',
  maxWidth: '88px',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: 'transparent'
  },
  '&:focus': {
    boxShadow: 'none',
    backgroundColor: 'transparent'
  }
}))

/**
 * TitleIcon
 * forwardProps: active
 */
const TitleIcon = ({ icon, ...props }) => {
  const Icon = styled(icon)(() => ({
    marginRight: '12px',
    fontSize: '24px',
    fill: 'none'
  }))
  return <Icon {...props} />
}
TitleIcon.propTypes = {
  /**
   * Icon component to be rendered
   */
  icon: PropTypes.func
}

const Accordion = ({
  caption,
  isOpen,
  children,
  nightMode,
  wrapText,
  onClick = () => {},
  onExpandClick = () => {},
  autoIndent,
  autoClose,
  ...rest
}) => {
  const [expanded, setExpanded] = useState(false)
  const open = autoClose ? isOpen : expanded

  const toggleAccordion = () => {
    if (!open) {
      onExpandClick()
    }
    onClick()
    if (!autoClose) {
      setExpanded(!expanded)
    }
  }
  return (
    <div className={styles.accordionContainer} {...rest}>
      <div
        className={styles.accordionButton}
        onClick={toggleAccordion}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') toggleAccordion()
        }}
      >
        <Typography
          className={cx(
            nightMode && styles.whiteText,
            wrapText && styles.breakSpaces
          )}
          type="greeting"
        >
          {caption}
        </Typography>
        {open ? (
          <CaratUp
            sx={{ fill: nightMode ? '#b3b8db' : '#979797', fontSize: '20px' }}
          />
        ) : (
          <CaratDown
            sx={{ fill: nightMode ? '#b3b8db' : '#979797', fontSize: '20px' }}
          />
        )}
      </div>
      <div
        className={cx(styles.accordionChildren, {
          expanded: open,
          autoIndent
        })}
      >
        {children}
      </div>
    </div>
  )
}

Accordion.propTypes = {
  caption: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  nightMode: PropTypes.bool,
  wrapText: PropTypes.bool,
  onExpandClick: PropTypes.func,
  autoIndent: PropTypes.bool,
  autoClose: PropTypes.bool
}

const ListChildren = ({
  items = [],
  cycle = 0,
  onExpandClick = () => {},
  onExpandItemAttr = '',
  nightMode = false,
  wrapText = false,
  autoIndent = true,
  dividers = true,
  customChildrenRender,
  onItemClick = () => {},
  actionIcon = '',
  onActionClick = () => {},
  autoClose = false,
  autoCloseLevel = -1
}) => {
  // Auto Close condition only for this iteration
  const levelAutoClose =
    autoClose && (autoCloseLevel === -1 || autoCloseLevel === cycle)
  const recursiveProps = {
    onExpandClick,
    onExpandItemAttr,
    nightMode,
    wrapText,
    autoIndent,
    dividers,
    customChildrenRender,
    onItemClick,
    actionIcon,
    onActionClick,
    autoClose,
    autoCloseLevel
  }

  // Accordions controler in order to only have one open at a time
  // When autoClose is true
  const [itemExpanded, setItemExpanded] = useState(-1)

  const onAccordionClick = idx => {
    if (levelAutoClose) {
      if (idx === itemExpanded) {
        setItemExpanded(-1)
      } else {
        setItemExpanded(idx)
      }
    }
  }
  return items.map((item, idx) => {
    if (item.children) {
      return (
        <Fragment key={item.key || item.id}>
          <Accordion
            caption={item.caption}
            isOpen={itemExpanded === idx}
            onClick={() => onAccordionClick(idx)}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') onAccordionClick(idx)
            }}
            onExpandClick={() => onExpandClick(item[onExpandItemAttr])}
            nightMode={nightMode}
            wrapText={wrapText}
            autoIndent={autoIndent}
            autoClose={levelAutoClose}
          >
            <ListChildren
              items={item.children}
              cycle={cycle + 1}
              {...recursiveProps}
            />
          </Accordion>
          {dividers && idx !== items.length - 1 && cycle === 0 && <Divider />}
        </Fragment>
      )
    }
    if (customChildrenRender && typeof customChildrenRender === 'function') {
      return (
        <Fragment key={item.key || item.id}>
          <div
            onClick={onItemClick}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') onItemClick()
            }}
          >
            {customChildrenRender(item)}
          </div>
        </Fragment>
      )
    }
    return (
      <div key={item.key || item.id}>
        <div className={styles.item}>
          <div
            className={cx(styles.itemCaption, onItemClick && styles.clickable)}
            onClick={onItemClick}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') onItemClick()
            }}
          >
            <Typography type="greeting">{item.caption}</Typography>
          </div>
          {actionIcon && !customChildrenRender && (
            <IconButton
              onClick={onActionClick}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') onActionClick()
              }}
              sx={{ width: '24px', height: '24px' }}
            >
              {actionIcon}
            </IconButton>
          )}
        </div>
      </div>
    )
  })
}

ListChildren.propTypes = {
  items: PropTypes.array,
  onExpandClick: PropTypes.func,
  onExpandItemAttr: PropTypes.string,
  nightMode: PropTypes.bool,
  wrapText: PropTypes.bool,
  autoIndent: PropTypes.bool,
  cycle: PropTypes.number,
  dividers: PropTypes.bool,
  customChildrenRender: PropTypes.func,
  onItemClick: PropTypes.func,
  actionIcon: PropTypes.string,
  onActionClick: PropTypes.func,
  autoClose: PropTypes.bool,
  autoCloseLevel: PropTypes.number
}

const PromptList = ({
  id,
  title,
  icon,
  items,
  actionIcon,
  onItemClick,
  onActionClick,
  customChildrenRender,
  isFetching = false,
  listClassName,
  onExpandClick = () => {},
  onExpandItemAttr = 'caption',
  button = false,
  buttonLabel = 'Submit',
  onButtonClick = () => {},
  collapsibleContent,
  nightMode = false,
  wrapText = false,
  manualOpenCallback,
  isOpen,
  containerClassName,
  dividers = true,
  autoIndent = true,
  autoClose = false,
  autoCloseLevel = -1
}) => {
  const [expanded, setExpanded] = useState(!collapsibleContent)
  const toggleAccordion = () => {
    if (manualOpenCallback) {
      manualOpenCallback(id)
    } else {
      setExpanded(!expanded)
    }
  }
  const childrenProps = {
    items,
    onExpandClick,
    onExpandItemAttr,
    nightMode,
    wrapText,
    autoIndent,
    dividers,
    customChildrenRender,
    onItemClick,
    actionIcon,
    onActionClick,
    autoClose,
    autoCloseLevel
  }

  return (
    <div className={cx(containerClassName, styles.container)}>
      <div
        className={cx(
          styles.titleContainer,
          collapsibleContent && items.length > 0 && styles.clickable
        )}
        onClick={toggleAccordion}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') toggleAccordion()
        }}
      >
        <div className={styles.flex}>
          {icon && <TitleIcon icon={icon} />}
          <Typography
            className={cx(styles.title, nightMode && styles.whiteText)}
            type="display2"
          >
            {title}
          </Typography>
        </div>
        {collapsibleContent &&
          (isFetching ? (
            <Spinner
              className={styles.smallSpinner}
              size={30}
              fillColor="transparent"
              strokeColor={nightMode ? '#b3b8db' : '#0164b0'}
            />
          ) : (
            items.length > 0 &&
            (expanded || isOpen ? (
              <CaratUp
                sx={{
                  color: nightMode ? '#b3b8db' : '#979797',
                  fontSize: '20px'
                }}
              />
            ) : (
              <CaratDown
                sx={{
                  color: nightMode ? '#b3b8db' : '#979797',
                  fontSize: '20px'
                }}
              />
            ))
          ))}
      </div>
      <div
        className={cx(
          collapsibleContent && items.length > 0 && styles.accordionChildren,
          styles.noMargin,
          { expanded: expanded || isOpen }
        )}
      >
        {isFetching ? (
          !collapsibleContent && (
            <Spinner
              fillColor="transparent"
              strokeColor={nightMode ? '#b3b8db' : '#0164b0'}
            />
          )
        ) : (
          <div className={cx(styles.itemList, listClassName)}>
            <ListChildren {...childrenProps} />
          </div>
        )}
        {button && (
          <PromptListButton onClick={onButtonClick} nightMode={nightMode}>
            {buttonLabel}
          </PromptListButton>
        )}
      </div>
    </div>
  )
}

PromptList.propTypes = {
  /**
   * List id
   */
  id: PropTypes.string,
  /**
   * List title
   */
  title: PropTypes.string.isRequired,
  /**
   * Section icon
   */
  icon: PropTypes.func,
  /**
   * Item list
   * Structure: { key, caption }
   */
  items: PropTypes.array.isRequired,
  /**
   * Action icon for each element
   */
  actionIcon: PropTypes.node,
  /**
   * Function
   * Executed when caption is clicked
   */
  onItemClick: PropTypes.func,
  /**
   * Function
   * Executed when icon is clicked
   */
  onActionClick: PropTypes.func,
  /**
   * Function
   * Custom Rendering for non parent items
   * Receives the item as a parameter
   */
  customChildrenRender: PropTypes.func,
  /**
   * Boolean
   * If true will render a spinner element
   */
  isFetching: PropTypes.bool,
  /**
   * String
   * Classname passed to the listContainer
   */
  listClassName: PropTypes.string,
  /**
   * Func
   * Accordion on click handler
   */
  onExpandClick: PropTypes.func,
  /**
   * String
   * Item Attribute that will be passed back
   *  in onExpandClick callback
   */
  onExpandItemAttr: PropTypes.string,
  /**
   * Have action button
   */
  button: PropTypes.bool,
  /**
   * Action Button Label
   */
  buttonLabel: PropTypes.string,
  /**
   * Action Button onClick
   */
  onButtonClick: PropTypes.func,
  /**
   * Boolean
   * Hide first items when collapsed
   */
  collapsibleContent: PropTypes.bool,
  /**
   * Boolean
   * Styles for dark backgrounds
   */
  nightMode: PropTypes.bool,
  /**
   * Boolean
   * Wrap words on overflow or trim
   */
  wrapText: PropTypes.bool,
  /**
   * Function
   * When opening needs to be controlled from Parent
   * Just when collapsibleContent = true
   */
  manualOpenCallback: PropTypes.func,
  /**
   * Boolean
   * Open variable when opening is manual
   */
  isOpen: PropTypes.bool,
  /**
   * String
   * Classname passed to the container
   */
  containerClassName: PropTypes.string,
  /**
   * Boolean
   * List Items should have dividers or not
   */
  dividers: PropTypes.bool,
  /**
   * Boolean
   * Children Items Left Margin
   */
  autoIndent: PropTypes.bool,
  /**
   * Boolean
   * Accordion Siblings will only be one open at a time
   */
  autoClose: PropTypes.bool,
  /**
   * Number
   * Level which should have autoClose
   * -1 : All levels (default)
   *  0 : First Level
   *  1 : ...
   * ...
   */
  autoCloseLevel: PropTypes.number
}

export default PromptList
